import React, { useState } from 'react';
import SEO from '../../components/SEO';

import imageIllustrationMortageFirstSectionMobile from '../../assets/images/illustrations/RemortgagePage/remortgage-first-section-mobile.png';
import imageIllustrationMortageFirstSection from '../../assets/images/illustrations/RemortgagePage/variable-mortgage-first.png';


import { default as imageSecondSliderFirst, default as imageSecondSliderFourth, default as imageSecondSliderSecond, default as imageSecondSliderThird } from "../../assets/images/illustrations/home-save-money-banner.png";

import { default as imageSecondSliderFirstMobile, default as imageSecondSliderFourthMobile, default as imageSecondSliderSecondMobile, default as imageSecondSliderThirdMobile } from "../../assets/images/illustrations/home-save-money-banner.png";



import loadable from '@loadable/component';
import { Link } from 'gatsby';
import { useMemo } from 'react';
import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import HeaderLeftContent from '../../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import FAQSection from '../../components/FAQSection';
import CustomImage from '../../components/Image/Image';
import useSliderHeight from '../../hooks/useSliderHeight';
import useWindow from '../../hooks/useWindow';

const ModalsFlow = loadable(() => import("../../components/Modals/ModalsFlow"))
const MortgageDetailsSection = loadable(() => import("../../components/CommonSections/MortgageDetails"))
const FullSizeSliderSectionMid = loadable(() => import("../../components/CommonSections/FullSizeSliderSectionMid"))
const SliderSection = loadable(() => import("../../components/CommonSections/SliderSection"))
const DownloadAppSection = loadable(() => import("../../components/CommonSections/DownloadAppSection"))
const ContactWidget = loadable(() => import("../../components/ContactWidget"))

const IS_MOBILE = () => (typeof window !== 'undefined' ? window.innerWidth <= 767.98 : 0);
const IS_TABLET = () => (typeof window !== 'undefined' ? window.innerWidth <= 991.98 : 0);





const FAQItems = [
  {
    title: 'Is a discount mortgage right for me?',
    description: (
      <>
      It is important to assess your personal circumstances and budgets in the long term and your risk tolerance before deciding on a discounted mortgage. If you expect interest rates to remain relatively stable and can handle potential rate increases later on, a discount variable rate mortgage might be a good option.
        <br />
        <br />
      Or if you believe that interest rates will decrease in the future, a discounted rate could be suitable.
      </>
    ),
  },
  {
    title: 'What is the ideal mortgage term length for me?',
    description: (
      <>
      The ideal mortgage term length depends on several factors on your individual circumstance and preferences. You need to take into consideration your long-term plans, your risk tolerance and financial restraints and commitments. It's worth speaking with a mortgage advisor for advice to see what options are available to you.
      </>
    ),
  },
  {
    title: 'How does a discount mortgage differ from a fixed-rate mortgage?',
    description: (
      <>
        Unlike a fixed-rate mortgage, where the interest rate will remain the same throughout the fixed term, a discount mortgage's interest rate can change with the lender's standard variable rate, (but at a discounted level for the initial period).
      </>
    ),
  },
  {
    title: 'How can I get a discounted mortgage?',
    description: (
      <>
        There are fewer discounted variable rate mortgages and tend to be less popular than fixed rates. One way to find out what discounted mortgages are available is to use a mortgage comparison site. Or you could speak to an advisor to see what options are available to you.
      </>
    ),
  },
  {
    title: 'How is the discount rate determined?',
    description: (
      <>
        The discount mortgage rate offered by lenders is typically based on the lender's SVR and the percentage discount rate they are willing to provide during the discounted period. Economic conditions, the Bank of England base rate and competition in the mortgage market can also influence the discount rate.
      </>
    ),
  },
  {
    title: 'Can I switch from a discount mortgage to a fixed-rate mortgage during the term?',
    description: (
      <>
        You may consider exiting your mortgage early should the interest rate on your mortgage deal rise. Most discount mortgages allow you to switch from a discount mortgage to a fixed-rate mortgage with the same lender or a different one. However, this process will likely involve an early repayment fee or exit fees, so it's essential to carefully consider the costs and benefits before making the switch.
      </>
    ),
  },
  {
    title: 'What happens when the discounted period ends?',
    description: (
      <>
        When the discounted variable rate mortgage ends in a discounted mortgage, the interest rate will no longer be discounted, and it will revert to the lender's SVR or another rate agreed upon in the loan terms. On an SVR mortgage it is likely your monthly repayments will increase. This standard variable rate is subject to market fluctuations and may change over time. As a result, borrowers should be prepared for the possibility of increased monthly payments after the discounted period expires.
      </>
    ),
  },
  {
    title: 'What is a Standard Variable Rate (SVR) mortgage?',
    description: (
      <>
        Standard Variable Rate (SVR) is the rate charged when your initial deal period comes to an end, which is usually at a higher rate.
      </>
    ),
  },
  {
    title: 'Can I negotiate the terms of the discount period in a discount mortgage?',
    description: (
      <>
        While lenders may have fixed terms for their discount mortgages, others might offer some flexibility. It is essential to discuss your preferences and needs with the lender or a mortgage broker to see if they can change the terms to suit your circumstances.
      </>
    ),
  },
];

function DiscountedRatePage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [dealsContactModal, setDealsContactModal] = useState(true)
  const { isDesktop, isMobile } = useWindow();
  const [isTablet, setTablet] = useState(false);
  const sliderHeight = useSliderHeight("611px", "198px", "234px")

  const secondSliderItems = useMemo(() => ([
    {
      image: (isMobile || isTablet) ? imageSecondSliderFirstMobile : imageSecondSliderFirst,
      title: "All you need to know about discount mortgages?",
      firstDescription: "What is a discount mortgage?",
      secondDescription: (
        <>
        A discount mortgage is a type of variable-rate mortgage. On a discount mortgage, the lender has the ability to change the rate on the mortgage at their own discretion. Similar to a tracker mortgage, that follows the Bank of England base, however, a discount mortgage follows the lender’s SVR rate so comes with even more unpredictability.<br /><br />
        As it’s up to the lender to decide the rate on the mortgage, the mortgage interest rate is offered at a discount on the lender’s standard variable rate (SVR). Discount mortgages still tend to be cheaper than an SVR mortgage.<br /><br />
        Standard Variable Rate (SVR) is the rate charged when your initial deal period comes to an end, which is usually at a higher rate.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderSecondMobile : imageSecondSliderSecond,
      title: "",
      secondDescription: (
        <>
          <b>Pros:</b><br /><br />
          On a variable-rate mortgage, you tend to pay a lower interest rate than your lender's SVR during the deal term.<br /><br />
          The interest rates are usually lower on discount mortgages than the fixed rates.<br /><br />
          <b>Cons:</b> <br /><br />
          There is more certainty and security on your monthly repayments with a fixed-rate mortgage and even a tracker mortgage over a discounted variable mortgage.<br /><br />
          On a discount mortgage, the lender can choose to change their SVR whenever they like, unlike with a tracker mortgage which follows the Bank of England base rate.<br /><br />
          How much you’re paying may vary which makes it difficult to budget and make financial decisions.<br /><br />
          Even though there may be a situation where the interest rate drops on your mortgage, there is likely to be a collar on the rate, meaning there will be a minimum interest rate that the lender will drop to.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderThirdMobile : imageSecondSliderThird,
      title:"What if I can no longer afford my monthly mortgage payments due to changes in SVR?",
      firstDescription: "The main disadvantage of a discount rate is that the monthly payments on your mortgage aren’t fixed so could increase suddenly.",
      secondDescription: (
        <>
        In the circumstance that the interest rate changes on your discount mortgage resulting in you not being able to afford your monthly payments, you may be able to remortgage to a deal on a lower interest however, you are likely to incur an early repayment charge (ERC) so will need to assess that this is worthwhile and cost-effective.<br /><br />
        Your lender may agree to a temporary solution while you’re unable to afford the monthly payments, such as switching to an interest-only deal, or you can take a break from your repayments. However, it’s worth noting that this will mean the cost and length of your mortgage will increase overall.
        </>
      ),
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderFourthMobile : imageSecondSliderFourth,
      title: "Who should take out a discount mortgage?",
      firstDescription: "Discounted variable mortgages tend to be the cheapest available, but are also one of the most uncertain ones. You need to take into count your personal circumstance and individual preferences before deciding a discounted variable rate mortgage is right for you.",
      secondDescription: (
        <>
        Here are some situations where a discount mortgage might be beneficial:<br /><br />
        Short-term homeowners: If you are planning to live in the property for a short period of time, a discount mortgage can provide lower interest payments during that time.<br /><br />
        Risk-tolerant borrowers: If you are comfortable with the possibility of your rate increasing after the discount period ends, a discount mortgage could be suitable.<br /><br />
        Expecting falling interest rates: It’s hard to predict how the market is going to go, but if you believe that interest rates will decrease in the future this
        </>
      )
    },
  ]), [isMobile, isTablet]);


  return (
    <>
      <SEO title="Remortgage Page" />
      <div className="common-page remortgage-page">
        <div className="remortgage-page__heading-container">
          <SectionWrapper isFirstSection={true}>
            <CustomHeadingSection
              leftContent={
                <HeaderLeftContent
                  title={
                    <>
                      What is a discount
                      {isDesktop ? <br /> : ' '}
                      mortgage?
                    </>
                  }
                  image={isMobile ? imageIllustrationMortageFirstSectionMobile : null}
                  imageReversed
                  description="In just a few clicks we can help you find personalised discount mortgage deals across the market."
                  buttonText="Compare Deals"
                  hashUrlOnClick="/mortgages/remortgage#mortgage-details"
                  useWidget
                />
              }
            />
            {!isMobile && (
              <div className="main-image-container">
                <CustomImage
                    className="w-100 main-image"
                    src={imageIllustrationMortageFirstSection}
                    alt="main image"
                  />
              </div>
            )}
          </SectionWrapper>
        </div>
        <div style={{minWidth: "100vw", minHeight: isMobile ? "826px" : "1000px"}}>
          <FullSizeSliderSectionMid
            sliderItems={secondSliderItems}
          />
        </div>
        <FAQSection
          FAQItems={FAQItems}
          title={
            <>
              Have a question about discount mortgages?
              {isDesktop ? <br /> : ' '}
              Check out our FAQ’s
            </>
          }
        />
        <div style={{minHeight: isMobile ? "382px" : "429px"}}>
          <DownloadAppSection />
        </div>
        <ContactWidget
          handleClick={() => {
            setIsContactModalOpen(true)
            setDealsContactModal(false)
          }}
          title="Speak to a mortgage expert for FREE"
        />
        <ModalsFlow
          isFirstOpened={isContactModalOpen}
          setIsFirstOpened={(state) => setIsContactModalOpen(state)}
          dealsContactModal={dealsContactModal}
        />
      </div>
    </>
  );
}



export default DiscountedRatePage;
